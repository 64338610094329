.parent-drawflow {
  display: flex;
  overflow: hidden;
  touch-action: none;
  outline: none;
  cursor: grab;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  /* width: calc(100vw - 81px); */
}

.parent-drawflow:active {
  cursor: grabbing;
}

#drawflow {
	border: 1px solid #353759;

  background-color: #1a1c42;
  background-position: 13px 13px;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      rgba(61, 177, 255, 0.15) 25%,
      rgba(61, 177, 255, 0.15) 26%,
      transparent 27%,
      transparent 74%,
      rgba(61, 177, 255, 0.15) 75%,
      rgba(61, 177, 255, 0.15) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      rgba(61, 177, 255, 0.15) 25%,
      rgba(61, 177, 255, 0.15) 26%,
      transparent 27%,
      transparent 74%,
      rgba(61, 177, 255, 0.15) 75%,
      rgba(61, 177, 255, 0.15) 76%,
      transparent 77%,
      transparent
    );
  background-size: 28px 28px;
}

.smooth-transition {
  transition: transform 599ms ease-in-out !important;
}

.drawflow {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
}

.drawflow .parent-node {
  position: relative;
}

.drawflow .drawflow-node {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  background: #ebebeb;
  width: 350px;
  min-height: 140px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  color: black;
  z-index: 2;
}

.drawflow .drawflow-node.selected {
  box-shadow: 0px 0px 6px aqua;
  background-color: #ebebeb;
}
.drawflow .drawflow-node:hover {
  cursor: pointer;
}
.drawflow .drawflow-node .inputs,
.drawflow .drawflow-node .outputs {
  width: 0;
}

.drawflow .drawflow-node .inputs,
.drawflow .drawflow-node .outputs,
.drawflow .drawflow-node .drawflow_content_node {
  padding-top: 32px;
}

.drawflow .drawflow-node .outputs {
  padding: 32px 16px;
}

.drawflow .drawflow-node .drawflow_content_node {
  padding: 32px 48px 0px 4px;
  font-size: 1.1rem;
  margin-right: 10px;
}

.drawflow .drawflow-node .outputs .output:after {
  content: var(--o);
  position: absolute;
  right: 24px;
  max-width: 80px;
  font-size: 13px;
  font-weight: bold;
  min-width: 80px;
  text-align: end;

  max-width: 10ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.drawflow .drawflow-node .outputs .output:hover:after {
  content: var(--o);
  background: #8b8b8b;
  color: #fff;
  width: auto;
  height: 30px;
  text-align: center;
  display: inline-table;
  align-items: center;
  justify-content: center;
  /* left: 25px; */
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 5px;

  padding: 5px 8px 0px 8px;
}

.drawflow .drawflow-node .drawflow_content_node {
  width: 100%;
  display: block;
}

.drawflow .drawflow-node .input,
.drawflow .drawflow-node .output {
  position: relative;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 50%;
  border: 2px solid black;
  cursor: crosshair;
  z-index: 1;
  margin-bottom: 12px;
}

.drawflow .drawflow-node .input {
  left: -22px;
  top: 0px;
  /* background: yellow; */
}

.drawflow .drawflow-node .output {
  right: -22px;
  top: 10px;
  position: relative;
}

.drawflow svg {
  z-index: 0;
  position: absolute;
  overflow: visible !important;
}
.drawflow .connection {
  position: absolute;
  transform: translate(9999px, 9999px);
}
.drawflow .connection .main-path {
  fill: none;
  stroke-width: 3px;
  stroke: #fff;
  transform: translate(-9999px, -9999px);
}
.drawflow .connection .main-path:hover {
  stroke: #1266ab;
  cursor: pointer;
}

.drawflow .connection .main-path.selected {
  stroke: #2680eb;
  stroke-width: 5px;
}

.drawflow .connection .point {
  cursor: move;
  stroke: black;
  stroke-width: 2;
  fill: white;
  transform: translate(-9999px, -9999px);
}

.drawflow .connection .point.selected,
.drawflow .connection .point:hover {
  fill: #1266ab;
}

.drawflow .main-path {
  fill: none;
  stroke-width: 5px;
  stroke: steelblue;
}
.drawflow .selectbox {
  z-index: 3;
  position: absolute;
  transform: translate(9999px, 9999px);
}
.drawflow .selectbox rect {
  fill: blue;
  opacity: 0.5;
  stroke: yellow;
  stroke-width: 5;
  stroke-opacity: 0.5;
  transform: translate(-9999px, -9999px);
}

.drawflow-drawflow-delete-parent {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  background: black;
  color: white;
  z-index: 4;
  border: 2px solid white;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  font-family: monospace;
  cursor: pointer;
}
.drawflow > .drawflow-delete-parent {
  margin-left: -15px;
  margin-top: 15px;
}

.parent-node .drawflow-delete-parent {
  right: -15px;
  top: -15px;
}

.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 162px;
}

.title-box {
  font-weight: bold;
}

.icon {
  margin-bottom: 16px;
  cursor: pointer;
  color: #000;
}

.icon svg {
  width: 20px;
  height: 20px;
}

.icon svg:hover {
  fill: #0e1039;
}

.text {
  overflow-wrap: anywhere;
  white-space: break-spaces;
}

.lable-output {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

.lable-output span {
  margin: 5px;
  padding-right: 8px;
}

.container-body {
  display: flex;
  flex-direction: row;
  height: fit-content;
}

.footer {
  padding-top: 16px;
  font-weight: bold;
}

.drawflow-delete-parent {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  background: black;
  color: white;
  z-index: 4;
  border: 2px solid white;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  font-family: monospace;
  cursor: pointer;
}
.drawflow > .drawflow-delete-parent {
  margin-left: -15px;
  margin-top: 15px;
}

.parent-node .drawflow-delete-parent {
  right: -15px;
  top: -15px;
}

.add-intent {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 226px;
  width: 56px;
  height: 56px;
  margin-left: 16px;
  border-radius: 50%;
  z-index: 400;
  background-color: #f3537e;
  color: #fff;
}

.container-zoom {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  bottom: 10px;
  z-index: 400;
  border-bottom-left-radius: 4px;
  background-color: transparent;
}

.node-selected {
  box-shadow: 0px 0px 14px #f3537e !important;
  border: 4px solid #f3537e !important;

  z-index: 100;
}

.header-node-selected {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.node-search {
  box-shadow: 0px 0px 10px #ff8036 !important;
  border: 4px solid #ff8036 !important;
}

.node-highlighted {
  box-shadow: 0px 0px 10px #ff1100 !important;
  border: 4px solid #ff1100 !important;
}

.node-created {
  box-shadow: 0px 0px 10px #0e1039 !important;
  border: 4px solid #0e1039 !important;
}

.separator-button {
  border-right: lightgray solid 1px !important;
  border-radius: 0 !important;
  width: 0;
  padding: 12px 0 !important;
}
