.select {
  height: 56px;
  width: 260px;
  margin: 0 5px;
  border: 1px solid #0000001f;
  border-radius: 4px;
  padding: 4px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto Slab", sans-serif;
}
