.modal-container {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    width: 80%;
  }
  
  .button-options {
    cursor: pointer;
    font-size: 20px;
    margin: 8px;
    padding: 16px;
    background-color: #0e1039;
    color: white;
  }
  
  .button-cancel {
    cursor: pointer;
    font-size: 20px;
    margin: 8px;
    padding: 16px;
  }
  