#blocklyDiv,
#blocklyDiv1 {
  height: calc(100% - 74px);
  width: 100%;
  /* position: absolute; */
}

.alert {
  padding: 20px;
  background-color: #bdf0bb;
  color: white;
  opacity: 1;
  transition: opacity 0.6s;
  margin-bottom: 15px;
  border-radius: 8px;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

.square_btn {
  display: inline-block;
  padding: 0.3em 1em;
  text-decoration: none;
  color: #5c5c5c;
  border: solid 2px #838383;
  border-radius: 5px;
  transition: 0.4s;
}

.square_btn:hover {
  background: #2c9e3b;
  color: rgb(0, 0, 0);
}

table {
  height: 100%;
  width: 100%;
}

#blocklyArea {
  height: 99%;
}

.blocklyWidgetDiv {
	position: fixed !important;
}


